.container {
   width: 100%;
   min-height: 100vh;
   background-size:     cover;              
   background-repeat:   no-repeat;
   background-position: center center;
   background-image:url("../Assets/Group 3.png")
}

.bg {
   width: 100%;
   height: 100vh;
   /* min-height: 50vh; */
}

.main {
   /* position: absolute; */
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   width: 100%;
   /* height: 100vh; */
   z-index: 1;
}

.medisage-logo {
   padding: 10px;
   width: 8%;
   height: 100%;
}

.logo {
   width: 15%;
   height: 100%;
   float: right;
}

.title {
   width: 65%;
   height: 100%;
}

.host {
   width: 100%;
   text-align: center;
   font-size: 1.7rem;
   font-weight: 400;
}

.hostedTitle {
   margin-right: 13rem;
}

.body {
   /* width: 0%; */
   margin: 0px 13rem;
   padding: 20px;
   position: relative;
}

.timeContent {
   display: flex;

}

.descDiv {
   flex: 3;
   text-align: center;
}

.desc {
   padding: 15px;
   border-radius: 20px;
   font-size: 25px;
   font-weight: bold;
   background-color: #FAE39F;
}

.timeVenue {
   flex: 1;
   padding: 20px;
   text-align: center;
}

.time {
   width: 75%;
   height: 100%;
}

.judgesMasters {
   display: flex;
}

.masters {
   flex: 2;
   text-align: center;
}

.judges {
   flex: 1;
   text-align: center;
}

.text {
   font-size: 18px;
   font-weight: bold;
   margin-top: -1rem;
}

.master-logo {
   width: 85%;
   height: 100%;
}

.jugdeImage {
   width: 100px;
}

.jugesCards {
   display: flex;
   justify-content: space-evenly;
}

.dr-name {
   color: white;
   font-weight: bold;
   font-size: 16px;
}

.footer {
   display: flex;
   padding-top: 40px;
}

.qr-div {
   flex: 1;
   text-align: end;
   padding-right: 20px;
}

.qr {
   width: 50%;
}

.button {
   flex: 1;
   display: flex;
   align-items: center;
}

button {
   background: rgb(250, 227, 159);
   background: linear-gradient(90deg, rgba(250, 227, 159, 1) 0%, rgba(25, 130, 196, 1) 36%, rgba(4, 67, 150, 1) 100%);
   border: none;
   padding: 5px 20px;
   color: #fff;
   font-size: 30px;
   font-weight: bold;
   border-radius: 10px;
   display: flex;
   cursor: pointer;

}

.buttonlogo {
   width: 35px;
}

.link{
   color: #fff;
   text-decoration: none;
   display: flex;
}

@media screen and (max-width:1920px) and (min-width:1600px) {
   .title {
      width: 80%;
   }
}

@media screen and (max-width:1300px) {
   .master-logo {
      width: 100%;
   }

   .masterCards {
      text-align: left;
   }

   .qr-div {
      padding-right: 40px;
   }

   .title {
      width: 80%;
   }

   .body {
      margin: 0 6rem;
   }
}

@media screen and (max-width:1250px) {
   .hostedTitle {
      margin-right: 6rem;
   }

   .host {
      font-size: 1.2rem;
   }
}

@media screen and (max-width:1000px) {
   .body {
      margin: 1rem 1rem 0rem 1rem;
   }
}

@media screen and (max-width:700px) {
   .medisage-logo{
      width: 25%;
   }
   .logo{
      width: 25%;
   }
   .title{
      /* margin-top: -40px; */
      width: 95%;
   }
   .hostedTitle{
      margin-right: 0;
   }
   .timeContent{
      flex-direction: column;
   }
   .desc{
      font-size: 15px;
      padding: 10px;
      border-radius: 10px;
   }
   .timeVenue{
      padding: 0px 10px;
   }
   .time{
      width:90%;
   }
   .judgesMasters{
      flex-direction: column;
   }
   .text{
      margin-top: 1rem;
   }
   .master-logo{
      width: 80%;
   }
   .masterCards{
      text-align: center;
   }
   .jugdeImage{
      width: 88px;
   }
   button{
      font-size: 16px;
   }
   .qr{
      width: 100%;

   }
   .bg{
      height: 100%;
      object-fit: contain;
   }
}